<template>
  <main class="org-details-form-wrapper">
    <section class="visibility-wrapper gap16">
      <div class="width100 flex gap8 justify-end">
        <p class="-xstb">Public</p>
        <p class="-xstb">Private</p>
      </div>
      <div
        v-for="option in privacyOptions"
        :key="option"
        class="width100 flex justify-space-between option"
      >
        <p class="-xstb" :title="option.description">{{ option.name }}</p>
        <div class="chkbox-wrapper">
          <span
            class="chkbox"
            :class="{ 'chkbox-active': option.state }"
            @click="toggleState(option, true)"
          ></span>
          <span
            class="chkbox"
            :class="{ 'chkbox-active': !option.state }"
            @click="toggleState(option, false)"
          ></span>
        </div>
      </div>
    </section>
    <section class="notifications-wrapper">
      <div
        v-for="option in notificationOptions"
        :key="option"
        class="width100 flex justify-space-between align-center setting gap8"
      >
        <p class="-xstb">{{ option.title }}</p>

        <div class="settings-container">
          <p v-for="setting in option.options" :key="setting" class="-xstb">
            {{ setting }}
          </p>
        </div>
      </div>
    </section>
  </main>
  <section class="btns-container">
    <BaseCancelSave />
  </section>
</template>

<script>
import BaseCancelSave from "../../utils/BaseCancelSave.vue";
export default {
  components: {
    BaseCancelSave,
  },
  data() {
    return {
      privacyOptions: [
        {
          name: "Organization Name",
          state: true, //true means public, false means private
          description: "Placholder description",
        },
        {
          name: "Organization Logo",
          state: true, //true means public, false means private
          description: "Placholder description",
        },
        {
          name: "Organization Description",
          state: true, //true means public, false means private
          description: "Placholder description",
        },
        {
          name: "Organization Location",
          state: false, //true means public, false means private
          description: "Placholder description",
        },
        {
          name: "Area of Expertise",
          state: true, //true means public, false means private
          description: "Placholder description",
        },
        {
          name: "Organization Size",
          state: false, //true means public, false means private
          description: "Placholder description",
        },
        {
          name: "Organization Sector",
          state: true, //true means public, false means private
          description: "Placholder description",
        },
        {
          name: "Organization Phone Number",
          state: false, //true means public, false means private
          description: "Placholder description",
        },
        {
          name: "Organization Address",
          state: false, //true means public, false means private
          description: "Placholder description",
        },
        {
          name: "Organization City",
          state: false, //true means public, false means private
          description: "Placholder description",
        },
        {
          name: "Organization Zip Code",
          state: false, //true means public, false means private
          description: "Placholder description",
        },
      ],
      notificationOptions: [
        {
          title: "Invite users to join your organization",
          options: ["Anyone", "Project Coordinator"],
          description:
            "Who will be responsible for inviting users to join your organization?",
        },
        {
          title: "Create Team",
          options: ["Anyone", "Project Coordinator"],
          description: "Who will be responsible for creating teams.",
        },
        {
          title: "Task is assigned",
          options: ["Agents Involved", "Entire Team"],
          description: "Behaviour for task assignment notifications.",
        },
        {
          title: "Task is overdue",
          options: ["Agents Involved", "Entire Team"],
          description: "Behaviour for task overdue notifications.",
        },
        {
          title: "Task status is updated",
          options: ["Agents Involved", "Entire Team"],
          description: "Behaviour for task status update notifications.",
        },
      ],
    };
  },
  methods: {
    toggleState(option, context) {
      if (context === option.state) return;
      option.state = !option.state;
    },
  },
};
</script>

<style scoped>
.org-details-form-wrapper {
  display: flex;
  width: 100%;
  gap: 20%;
}
.visibility-wrapper,
.notifications-wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
}
.option {
  border-bottom: 1px solid var(--gray6);
  padding-bottom: 0.8rem;
  text-align: left;
}

.width100.flex.gap8.justify-end > p {
  width: 42px;
}
.chkbox-wrapper {
  min-width: calc(80px + 1rem);
  display: flex;
  justify-content: space-around;
}
.chkbox {
  width: clamp(20px, 20px, 20px);
  height: clamp(20px, 20px, 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray5);
  border-radius: 4px;
}
.chkbox:hover {
  cursor: pointer;
  background: var(--focus3);
}
.chkbox-active {
  border-color: var(--secondary);
}
.chkbox-active::before {
  content: "\2713";
  font-size: 80%;
  color: var(--secondary);
}
.setting {
  border-bottom: 1px solid var(--gray6);
  padding-block: 0.8rem;
  text-align: left;
}
.settings-container {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid var(--gray5);
  border-radius: 8px;
  user-select: none;
}
.settings-container > p {
  white-space: nowrap;
  border-right: 1px solid var(--gray5);
  padding: 8px;
  border-radius: none;
}
.settings-container > p:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: none;
  border-bottom-right-radius: none;
}
.settings-container > p:last-child {
  border-right: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.settings-container p:hover {
  cursor: pointer;
  background: var(--focus3);
}
@media screen and (max-width: 1023px) {
  .org-details-form-wrapper {
    gap: 5%;
  }
}
@media screen and (max-width: 719px) {
  .org-details-form-wrapper {
    gap: 2rem;
    flex-flow: column;
  }
}
</style>